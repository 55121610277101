.hr-image-wrapper {
  background-color: white;
}

.content-wrapper {
  background: white !important;
}

.hr-image-links {
  height: 84px;
  width: 198px;
}

.form-manager-wrapper {
  padding: 20px;
  width: 640px;
  margin: 0 auto;
  border-width: 3px;
  border-style: solid;
  border-color: #ff8800;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}

.forms-link-box {
  padding: 50px;
  border: 3px solid rgb(212, 8, 0);
  box-sizing: border-box;
  border-radius: 7px;
  text-align: center;
  cursor: pointer;

  a {
    text-decoration: none;
    color: black;
  }
}

.container a {
  color: black !important;
  text-decoration: none !important;
}

.manager-other-links-wrapper {
  margin-top: 100px;
}

.center-image {
  display: block;
  margin: 0 auto;
}

.training-btn {
  background: rgb(0, 199, 32);
  width: 220px;
  height: 46px;
  margin: 0 auto;
  line-height: 16px;
  border-radius: 5px;
}

// .nav-profile {
//   margin-top: 30px;
// }

.filepond--wrapper {
  height: 100%;
  width: 100%;
}

.filepond--file {
  height: 100%;
}

.filepond--wrapper * {
  font-size: inherit;
  font-family: inherit;
  background: transparent;
}

.filepond--root {
  // max-height: 0.5px;
  max-height: 10em;
  background: transparent;
}

.filepond--root .filepond--drop-label {
  height: 150px !important;
}

.filepond--drop-label {
  // height: 100%;
  width: 100%;
  color: transparent;
}

.filepond--item-panel {
  background: transparent;
}

.modal__overlay,
.react-confirm-alert-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.75) !important;
}

// .modal {
//   width: 500px;
//   background: white;
//   border-radius: 5px;
//   padding: 40px;
//   outline: none;
//   border: none;
//   z-index: 5;
//   max-height: 100vh;
//   overflow: auto;
// }

.pageForms {
  input,
  select,
  textarea {
    // height: 50px;
    width: 100%;
    color: #555a55;
    z-index: 0;
    background: white;
    padding: 15px 20px;

    /* Experimental */
    font-weight: bold;
    padding: 10px 20px;
    transition: color 0.5s ease, border-color 0.5s ease, padding 0.25s ease;
    /* border-width: 0 0 2px 0; */
  }

  input:disabled,
  select:disabled,
  textarea:disabled {
    color: #606662;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: dashed;
    padding: 0 0 10px;
    font-weight: bold;
    // font-size: 16px;
    background: #f9fbf9;
    background: transparent;
    height: unset;
    outline: none;
  }
}

input {
  transition: color 0.5s ease, border-color 0.5s ease, padding 0.25s ease;
}

select.form-control,
.swal2-modal .swal2-content select.swal2-input {
  color: black;
}

.domainBox {
  min-height: 120px;
  border: 1px dotted grey;
  padding: 20px;

  box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 15px;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(235, 239, 238);
  border-image: initial;

  a {
    color: #76838f;
    text-decoration: none;
    // :hover {
    //   text-decoration: none !important;
    // }
  }
}

.config-header-title {
  color: #3f50f6;
  font-weight: bold;

  span {
    color: #343a40;
    font-weight: 400;
  }
}

/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

#expenses_form tr td {
  padding: 0.9375rem;
}

#expenses_form tr td input {
  text-align: right;
}

.table-wider {
  width: 70%;
}

.closing_report_form td input {
  text-align: right;
}

.form-control:disabled {
  background-color: transparent;
}

.form-wizard .container .content {
  background-color: white;
  padding: 1rem !important;
}

.amountRow {
  font-weight: bold;
}

.input-group-text {
  color: black !important;
}

.tipShareSummary input {
  padding: 0 !important;
}

#previewStage input {
  padding: 0 !important;
  text-align: left !important;
}

.config-title {
  text-transform: capitalize;
}

.bar_payment_column {
  text-align: left !important;
}

#total_tip_row {
  position: relative;
  // top: -150px;
}

.instant_pay_total_group {
  border: 2px solid red;
}

.instant_pay_total_group input {
  font-weight: bolder;
}

#form-inline label {
  justify-content: left !important;
  margin-bottom: 3px;
}

#downloadPreview {
  padding-left: 40px;
  padding-top: 10px;
}

#downloadPreview .form-group label {
  font-size: 10px !important;
}

#downloadPreview table tr th {
  font-weight: bold;
  font-size: 10px !important;
  padding: 6px 0 6px 6px;
  color: red;
}

#downloadPreview table tr td {
  font-weight: bold;
  font-size: 11px !important;
  padding: 6px 0 6px 6px;
}

#downloadPreview label {
  font-weight: bold;
}

#downloadBonusPreview {
  padding-left: 40px;
  padding-top: 10px;
}

#downloadBonusPreview .form-group label {
  font-size: 10px !important;
}

#downloadBonusPreview table tr th {
  font-weight: bold;
  font-size: 10px !important;
  padding: 6px 0 6px 6px;
}

#downloadBonusPreview table tr td {
  font-weight: bold;
  font-size: 11px !important;
  padding: 6px 0 6px 6px;
}

#downloadBonusPreview label {
  font-weight: bold;
}

.totalColumns {
  font-weight: bold;
  color: red;
}

.depositForm {
  font-size: 11px;
}

.swal2-confirm {
  color: white !important;
}

.page {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

// #downloadPreview table tr td {
// padding: 0 !important;
// }

#downloadPreview .amountRow .form-group {
  padding-right: 5px;
  text-align: right;
  // margin-bottom: 0 !important;
}

#downloadPreview .form-group {
  margin-bottom: 0 !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    width: 260mm;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .page {
    width: 210mm;
  }

  #downloadPreview table tr th {
    font-weight: bold;
    color: red;
  }

  #downloadPreview table tr td {
    font-weight: bold;
  }

  #downloadPreview label {
    font-weight: bold;
  }

  .totalColumns {
    font-weight: bold;
    color: red;
  }
}

#reviewEntryForMaintenance {
  margin-top: 20px;
  margin-bottom: 20px;
}

#reviewEntryForMaintenance table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

#reviewEntryForMaintenance table tr:nth-child(even) {
  background-color: #dddddd;
}

#copy-icon {
  color: #ffffff !important;
}

.review-section {
  margin: 50px 0;
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  // size: auto;
  size: A4;
  margin: 10mm;
}

@media print {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }
}

#reportNoteModalWrapper {
  width: 50%;
}

.issues-btn-wrapper {
  margin: 10px 10px;
}

.issues-btn-wrapper button {
  width: 160px;
}

// .maintenance-report-list li {
//   padding: 10px;
//   border: 2px dotted;
//   margin: 10px 0px;
// }

.card-bottom div {
  display: inline-block;
  margin: 0px 5px;
  font-size: 14px;
}

.bonus-input-wrapper {
  padding-left: 0px !important;
}

.bonus-input-wrapper > input {
  padding: 1px !important;
  border: none;
  outline: 0;
}

.bonus-input-wrapper > span {
  padding-left: 10px !important;
  border: none;
  outline: 0;
}

.dark-background input {
  color: white;
  font-weight: bolder;
}

#new-form-wizard .container > div:not(.content) {
  margin-top: 20px;
  text-align: left;
}

.all-card-body {
  background: #e6e9ed;
}

.summary-card-wrapper {
  border: 1px dotted;
  padding: 10px;
}

.summary-card-wrapper span {
  text-decoration: underline;
  color: blue;
}

// Pagination

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}

@media only screen and (max-width: 600px) {
  .rate_my_boss_form p {
    inline-size: 300px;
    overflow-wrap: break-word;
  }
}
